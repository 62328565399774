/* --------- */
/* CSS RESET */
/* --------- */
html {
    color: #000;
    background: #FFF;
}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3,
h4, h5, h6, pre, code, form, fieldset, legend,
input, button, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
fieldset, img {
    border: 0;
}
address, caption, cite, code, dfn, em, strong,
th, var, optgroup {
    font-style: inherit;
    font-weight: inherit;
}
del, ins {
    text-decoration: none;
}
li {
    list-style: none;
}
caption, th {
    text-align: left;
}
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
}
q:before, q:after {
    content: '';
}
abbr, acronym {
    border: 0;
    font-variant: normal;
}
sup {
    vertical-align: baseline;
}
sub {
    vertical-align: baseline;
}
/*because legend doesn't inherit in IE */
legend {
    color: #000;
}
input, button, textarea, select, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}
/*@purpose To enable resizing for IE */
/*@branch For IE6-Win, IE7-Win */
input, button, textarea, select {
    *font-size: 100%;
}


/* ---------- */
/* MAIN STYLE */
/* ---------- */
html,
body
{
    overflow: hidden;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: none !important;
}

a {
    cursor: none;
}

.webgl
{
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
    z-index: -1;
}

#control-shade {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
}

#control-shade__left, #control-shade__right {
    height: 100%;
    width: 20%;
    cursor: none;
}

#control-shade__center {
    flex: 1;
    height: 100%;
}

#custom_cursor {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99;
}

#custom_cursor svg {
    position: relative;
    top: -25px;
    left: -25px;
    width: 50px;
    height: 50px;
    display: none;
}

#custom_cursor.arrow-right svg#arrow {
    display: block;
}

#custom_cursor.arrow-left svg#arrow {
    display: block;
    transform: rotate(180deg);
}

#custom_cursor.default svg#cursor_default,
#custom_cursor.image svg#cursor_default,
#custom_cursor.dot svg#cursor_default,
#custom_cursor.second-link svg#cursor_default,
#custom_cursor.dial svg#cursor_default,
#custom_cursor.link svg#cursor_default {
    display: block;
}

svg#cursor_default circle {
    stroke: rgb(0, 0, 0);
    fill: rgba(0, 0, 0, 0);
    stroke-width: 3px;
    transition: all .2s ease-in-out;
}

#custom_cursor.image svg#cursor_default circle,
#custom_cursor.dot svg#cursor_default circle,
#custom_cursor.second-link svg#cursor_default circle,
#custom_cursor.link svg#cursor_default circle {
    r: 5;
    stroke-width: 2px;
    stroke: rgb(49, 0, 61);
}

#custom_cursor.dial svg#cursor_default circle {
    r: 5;
    stroke-width: 1px;
    stroke: rgb(102, 102, 102);
}

#custom_cursor.dot svg#cursor_default circle {
    r: 1;
    stroke-width: 1px;
}

#custom_cursor.second-link svg#cursor_default circle {
    stroke: rgb(102, 102, 102);
}

.longDescription {
    --clipRadius: 50%;
    --clipY: -250%;
    clip-path: circle(var(--clipRadius) at 50% var(--clipY));    /* clip-path: circle(<radius> at <position-x> <position-y>) */
    color: #000;
    position: absolute;
    z-index: 20;
    left: 50%;
    bottom: 33px;
    transform: translateX(-50%);
    padding: 10px 42px;
    overflow: hidden;
}

.longDescription a {
    color: #000;
    text-decoration: none;
    position: relative;
 
}

.longDescription a:after {
    content: '';
    position: absolute;
    background: url('/static/arrow.svg');
    width: 27px;
    height: 12px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .3s ease-in-out;
    transform: translate(0,0);
    right: -35px;
    top: 50%;
    transform: translate(0px, -50%);
}

.longDescription a:hover:after {
    transform: translate(6px, -50%);
}

.split_line {
    position: relative;
    overflow: hidden;
}


.shortDescription {
    display: none;
}

#help {
    right: 40px;
    position: absolute;
    top: 50%;
    border: 1px solid #ddd;
    padding: 10px 13px;
    border-radius: 7px;
    transform: translateY(-50%);
}

#help .nav_label {
    color: #ccc!important;
}

@media (max-width: 768px) {
    .longDescription {
        bottom: 31px;
        padding: 10px 21px;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

    .longDescription a:after {
        position: relative;
        display: inline-block;
        right: auto;
        top: auto;
        transform: none;
        margin-left: 6px;
    }

    .longDescription a:hover:after {
        transform: none;
    }

    .split_line {
        float: left;
    }
}

.bottom {
    border: 5px solid #7b0420;
    background: #cccccc;    
    position: absolute;
    left: 10px;
    bottom: 10px;
    height: 10vh;
    width: 300px;
}

#nav .nav_label {
    z-index: 10;
    text-transform: uppercase;
    list-style-type: none;
    overflow: hidden;
    color:#000;
}

@media (min-width: 768px) {
    #home {
        overflow:hidden;
        position:absolute;
        top: 40px;
        left: 40px;
    }

    #about {
        position:absolute;
        top: 40px;
        right: 40px;
    }

    #portfolio {
        position:absolute;
        bottom: 40px;
        left: 40px;
    }

    #contact {
        position:absolute;
        bottom: 40px;
        right: 40px;
    }
}

#help-wrapper {
    position: absolute;
    width: 500px;
    text-align: right;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

#about-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 440px;
    max-width: calc(100vw - 80px);
    text-align: right;
}

#contact-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 440px;
    max-width: calc(100vw - 80px);
    text-align: right;
}

.help-outerLine,
.about-outerLine,
.contact-outerLine {
    overflow: hidden;
}

#contact-wrapper a {
    color: #000;
}

@media(max-width: 767px) {

    #nav { 
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 4px;
        grid-row-gap: 0px;
        background-color: #fef8f8;
    }

    #nav li { 
        padding: 8px 0px;
        text-align: center;
        font-size: 12px;
        background-color: #fef8f8;
    }

    #custom_cursor {
        display: none;
    }

    #help {
        display: none;
    }

    #about-wrapper {
        top: auto;
        bottom: 33px;
        transition: background-color .4s ease-in-out;
        padding: 2rem 1rem;
        position: fixed;
        max-width: 100%!important;
        box-sizing: border-box;
    }

    #about:hover #about-wrapper {
        background-color: #fef8f8;
    }

    .about-innerLine {
        margin-bottom: 0;
        color: #000;
        background: none;
    }

    #contact-wrapper {
        bottom: 33px;
        right: 0px;
        transition: background-color .4s ease-in-out;
        padding: 2rem 1rem;
        position: fixed;
        width: auto;
    }

    #contact:hover #contact-wrapper {
        background-color: white;
    }
    
}
